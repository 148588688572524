import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import dimensions from "styles/dimensions"
import Layout from "components/Layout"
import { Section as Sec } from "."
import colors, { color_from_index } from "styles/colors"
import { Transition } from "react-transition-group"

const PageTitle = styled("h1")`
  margin-bottom: 1em;
`

const BookshelfTitle = styled("h2")`
  margin: 0.2em 0em;
  color: ${colors.orange500};
`

const Section = styled(Sec)`
  margin-bottom: 2em;

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    margin-bottom: 0em;
  }

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    margin-bottom: 0em;
  }
`

// const BookshelfDescription = styled("div")`
//   margin: 0 auto;

//   .block-img {
//     margin-top: 3.5em;
//     margin-bottom: 0.5em;

//     img {
//       width: 100%;
//     }
//   }

//   a {
//     /* text-decoration: none; */
//     transition: all 100ms ease-in-out;

//     &:nth-of-type(1) {
//       color: ${colors.orange500};
//     }

//     &:hover {
//       cursor: pointer;
//       transition: all 100ms ease-in-out;

//       &:nth-of-type(1) {
//         color: ${colors.orange500};
//         background-color: ${colors.orange200};
//       }
//     }
//   }

//   .embed {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     padding: 2em 0 2em 0;
//   }
// `

const BookshelfSubtitle = styled("h3")`
  margin: 0.5em 0em;
  margin-bottom: 1em;
  font-weight: 300;
  font-family: sans-serif;
  color: ${colors.grey500};
`

const BookHeader = styled("div")`
  display: flex;
  flex-direction: column;
`

const BookTitle = styled("h3")`
  text-weight: strong;
  margin-bottom: 0;
`

const BookAuthor = styled("h5")`
  margin-top: 0.5em;
  color: ${colors.grey700};
  margin-bottom: ${props => (props.expanded ? `0.2em` : `1em`)};
`

const BookRow = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  transition: all 100ms ease-in-out;
  padding: 0em 2em;

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    display: block;
    padding: 0.5em;
  }

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    display: block;
    padding: 0.5em;
  }

  &:hover {
    cursor: pointer;
    transition: all 100ms ease-in-out;
    border-radius: 8px;

    &:nth-of-type(1) {
      color: ${colors.blue600};
      background-color: ${colors.blue200};
    }
    &:nth-of-type(2) {
      color: ${colors.orange600};
      background-color: ${colors.orange200};
    }
    &:nth-of-type(3) {
      color: ${colors.purple600};
      background-color: ${colors.purple200};
    }
    &:nth-of-type(4) {
      color: ${colors.green600};
      background-color: ${colors.green200};
    }
    &:nth-of-type(5) {
      color: ${colors.teal600};
      background-color: ${colors.teal200};
    }
  }
`

const BookGenre = styled("h4")`
  background-color: ${props => color_from_index(props.genre.charCodeAt(0))};
  border-radius: 4px;
  color: white;
  padding: 4px 10px;
  text-weight: strong;

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    text-weight: normal;
    font-size: 12px;
    margin: 0;
    display: inline-block;
  }

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    text-weight: normal;
    font-size: 12px;
    margin-top: 0;
    display: inline-block;
  }
`

const BookThoughts = styled("div")`
  padding: 1em 0em;
  color: ${colors.grey600};
`

const BookRating = styled("div")`
  padding: 0.2em 0em;
`

const BookExtra = styled("div")`
  transition: all 500ms ease-in-out;
  opacity: ${({ state }) =>
    state === "entering" || state === "exiting" ? 0 : 1};
  /* display: ${({ state }) => (state === "exited" ? "none" : "block")}; */
  /* max-height: ${({ state }) =>
    state === "entering" || state === "exiting" ? `0px` : `1000px`}; */
  /* -webkit-transition: max-height 1s;
  -moz-transition: max-height 1s;
  -ms-transition: max-height 1s;
  -o-transition: max-height 1s;
  transition: max-height 1s; */
`

const Rating = ({ rating }) => <BookRating>{`⭐️ `.repeat(rating)}</BookRating>

const Bookshelf = ({ books, bookshelf, meta }) => {
  const [expandedRow, setExpanded] = React.useState(null)

  const onClickRow = index => {
    if (expandedRow === index) {
      setExpanded(null)
    } else {
      setExpanded(index)
    }
  }

  const Book = ({
    book_author,
    book_date_finished,
    book_date_started,
    book_title,
    book_genre,
    book_thoughts,
    book_rating,
    expanded,
    index,
  }) => (
    <BookRow onClick={() => onClickRow(index)}>
      <BookHeader>
        <BookTitle key={book_title[0].text}>{book_title[0].text}</BookTitle>
        <BookAuthor key={book_author[0].text} expanded={index === expandedRow}>
          {book_author[0].text}
        </BookAuthor>
        <Transition in={expanded} timeout={10} appear mountOnEnter>
          {state => (
            // state change: exited -> entering -> entered -> exiting -> exited
            <BookExtra state={state}>
              <Rating rating={book_rating} />
              {book_thoughts && (
                <BookThoughts>{book_thoughts[0].text}</BookThoughts>
              )}
            </BookExtra>
          )}
        </Transition>
      </BookHeader>
      <BookGenre genre={book_genre[0].text}>{book_genre[0].text}</BookGenre>
    </BookRow>
  )

  return (
    <>
      <Helmet
        title={`📙 bookshelf | dhruv c`}
        titleTemplate={`%s | bookshelf | dhruv c`}
        meta={[
          {
            name: `description`,
            content: meta.description,
          },
          {
            property: `og:title`,
            content: `bookshelf | dhruv c`,
          },
          {
            property: `og:description`,
            content: meta.description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: meta.author,
          },
          {
            name: `twitter:title`,
            content: meta.title,
          },
          {
            name: `twitter:description`,
            content: meta.description,
          },
        ].concat(meta)}
      />
      <Layout>
        <PageTitle>Bookshelf</PageTitle>
        <Section>
          {bookshelf.bookshelf_title && (
            <BookshelfTitle>{bookshelf.bookshelf_title[0].text}</BookshelfTitle>
          )}
          {bookshelf.bookshelf_subtitle && (
            <BookshelfSubtitle>
              {bookshelf.bookshelf_subtitle[0].text}
            </BookshelfSubtitle>
          )}
        </Section>
        <Section>
          {books.map((book, index) => (
            <Book
              key={book.title}
              index={index}
              book_title={book.node.book_title}
              book_author={book.node.book_author}
              book_genre={book.node.book_genre}
              book_thoughts={book.node.book_thoughts}
              book_rating={book.node.book_rating}
              expanded={index === expandedRow}
            />
          ))}
        </Section>
      </Layout>
    </>
  )
}

export default ({ data }) => {
  const books = data.prismic.allBooks.edges
  const bookshelf = data.prismic.allBookshelfs.edges.slice(0, 1).pop()
  const meta = data.site.siteMetadata
  if (!books || !bookshelf) return null

  return <Bookshelf books={books} bookshelf={bookshelf.node} meta={meta} />
}

Bookshelf.propTypes = {
  books: PropTypes.array.isRequired,
  bookshelf: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
}

export const query = graphql`
  {
    prismic {
      allBooks {
        edges {
          node {
            book_title
            book_author
            book_rating
            book_date_started
            book_date_finished
            book_genre
            book_description
            book_thoughts
          }
        }
      }
      allBookshelfs {
        edges {
          node {
            bookshelf_title
            bookshelf_subtitle
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
